
.wpo-contact-form-map .contact-form {
  padding: 60px 65px 30px;
  -webkit-box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
  box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
  border-radius: 5px;
}

@media (max-width: 1199px) {
  .wpo-contact-form-map .contact-form {
      margin-bottom: 60px;
  }
}

@media (max-width: 600px) {
  .wpo-contact-form-map .contact-form {
      padding: 40px 35px;
  }
}

.wpo-contact-form-map .contact-form h2 {
  font-size: 30px;
  font-size: 1.875rem;
  font-weight: 500;
  margin: 0 0 1em;
  text-align: center;
}

@media (max-width: 767px) {
  .wpo-contact-form-map .contact-form h2 {
      font-size: 25px;
      font-size: 1.5625rem;
  }
}

.wpo-contact-form-map form input,
.wpo-contact-form-map form select,
.wpo-contact-form-map form textarea {
  background: transparent;
  width: 100%;
  height: 50px;
  border: 0;
  border-bottom: 1px solid #d0d4df;
  border-radius: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  padding-left: 0;
  color: #525252;
}
.wpo-contact-form-map form p{
  font-size: 13px;
  color: red;
  margin-top: 5px;
}
@media (max-width: 991px) {

  .wpo-contact-form-map form input,
  .wpo-contact-form-map form select,
  .wpo-contact-form-map form textarea {
      height: 45px;
  }
}

.wpo-contact-form-map form input:focus,
.wpo-contact-form-map form select:focus,
.wpo-contact-form-map form textarea:focus {
  border-color: #029ca5;
}

.wpo-contact-form-map form textarea,
.volunteer-contact-form form textarea {
  height: 125px;
}

.wpo-contact-form-map form {
  overflow: hidden;
}

.wpo-contact-form-map form ::-webkit-input-placeholder {
  font-style: 16px;
  font-style: normal;
  color: #888;
}

.wpo-contact-form-map form :-moz-placeholder {
  font-style: 16px;
  font-style: normal;
  color: #888;
}

.wpo-contact-form-map form ::-moz-placeholder {
  font-style: 16px;
  font-style: normal;
  color: #888;
}

.wpo-contact-form-map form :-ms-input-placeholder {
  font-style: 16px;
  font-style: normal;
  color: #888;
}

.wpo-contact-form-map form select {
  display: inline-block;
  color: #a9a9a9;
  cursor: pointer;
  opacity: 1;
  padding: 6px 12px;
  font-size: 15px;
  font-size: 0.9375rem;
}

.wpo-contact-form-map form>div {
  margin-bottom: 15px;
}


.wpo-contact-form-map .submit-area {
  width: 100%;
  float: none;
  clear: both;
  text-align: center;
  margin-top: 30px;
}

.wpo-contact-form-map .contact-map {
  max-width: 948px;
  height: 575px;
  -webkit-box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
  box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.1);
}

@media (max-width: 767px) {
  .wpo-contact-form-map .contact-map {
      height: 500px;
  }
}

.wpo-contact-form-map .contact-map iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.contact-map {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

.contact-form .theme-btn{
  color: #fff;
  outline: none;
  margin-top: 20px;
  border-radius: 40px;
}

.contact-form .form-submit{
  text-align: center;
}

.wpo-contact-form-map .contact-form {
  margin-bottom: 60px;
}

.wpo-contact-form-map .info-wrap {
  /* display: flex; */
  /* align-items: center; */
}

.wpo-contact-form-map .info-icon,
.wpo-contact-form-map .info-icon-2,
.wpo-contact-form-map .info-icon-3 {
  width: 50px;
  height: 50px;
  line-height: 115px;
  border-radius: 50%;
  background-color: #009846;
  box-shadow: 0px 10px 40px 0px rgba(50, 50, 50, 0.1);
  text-align: center;
  /* margin-right: 20px; */
  align-items: center;
  color: white;
}

.wpo-contact-form-map .info-icon-2 {
  background-color: #009846;
}

.wpo-contact-form-map .info-icon-3 {
  background-color: #009846;
}

.info-item {
  -webkit-box-shadow: 0px 10px 40px 0px rgba(50, 50, 50, 0.1);
  -moz-box-shadow: 0px 10px 40px 0px rgba(50, 50, 50, 0.1);
  box-shadow: 0px 10px 40px 0px rgba(50, 50, 50, 0.1);
  padding: 20px 15px;
  margin-bottom: 30px;
}

.info-item h2 {
  font-size: 24px;
  color: #242f6c;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 25px;
}

.info-icon-2 .fi:before {
  font-size: 35px;
}

.info-text span {
  color: #666666;
  font-size: 18px;
  font-weight: 600;
}

@media(max-width: 991px) {
  .wpo-contact-info {
      padding-left: 0;
  }

  .wpo-contact-form-map .contact-map {
      margin-bottom: 40px;
  }
}

@media(max-width: 590px) {
  .info-item h2 {
      font-size: 20px;
  }

  .info-text span {
      font-size: 15px;
  }

  .wpo-contact-form-map form textarea {
      height: 100px;
  }
}

.wpo-contact-info .info-icon i,
.wpo-contact-info .info-icon-2 i,
.wpo-contact-info .info-icon-3 i {
  font-size: 40px;
  line-height: 90px;
}

.wpo-contact-form-map.section-padding {
  padding-bottom: 90px;
}

@media(max-width: 767px) {
  .wpo-contact-form-map.section-padding {
      padding-bottom: 50px;
  }
}
